import axios from "axios";
import toast from "../toast";
import { merge } from "lodash";
import { getValueCols } from "./ag-grid-cell-utils";
import { renderGridTable } from "./render-grid-table";
import cellRenderers from "./ag-grid-cell-renderers";

/**
 * AGGrid Table using the /ag-grid endpoint fetching data in a Unified way across the app.
 */
class AGGridTable {
  constructor({ serverUrl, path = "ag-grid", type, getQuery, totalsMapper }) {
    this.fullPath = `${serverUrl}${path}/${type}`;
    this.totalsMapper = totalsMapper;
    this.getQuery = getQuery || (() => ({}));
    this.gridOptions = null; // Populated inside the renderGridTable function
    this.renderGridTableOptions = null;
  }

  init(
    {
      tableReference,
      holder,
      reportingParams,
      contextMenuOverride,
      columnDefs
    },
    gridOptions
  ) {
    const self = this;
    const pageSize = 50;

    const onGridReady = gridOptions?.onGridReady;
    if (onGridReady) {
      delete gridOptions.onGridReady;
    }

    const options = merge(
      {
        columnDefs,
        defaultColDef: {
          suppressAggFuncInHeader: true,
          cellRenderer: cellRenderers.placeholder,
          filter: "agTextColumnFilter"
        },
        rowSelection: "multiple",
        rowModelType: "serverSide",
        cacheBlockSize: pageSize,
        maxBlocksInCache: 10,
        suppressRowClickSelection: true,
        blockLoadDebounceMillis: 100,
        enableSorting: true,
        serverSideSortingAlwaysResets: true,
        suppressValuesToolPanel: true,
        onGridReady: (params) => {
          this.gridApi = params.api;
          onGridReady?.(params);
        },
        serverSideDatasource: {
          getRows(params) {
            const request = params.request;
            const searchValue =
              params.parentNode.gridOptionsWrapper.gridOptions.searchValue;
            request.searchValue = searchValue ? searchValue : null;
            request.valueCols = getValueCols(params);
            self
              .fetchBoard(request)
              .then((res) => {
                const lastRow = res.rows.length === pageSize ? -1 : res.lastRow;
                if (res.totals?.length && self.totalsMapper) {
                  self.gridOptions.api.setPinnedBottomRowData(
                    self.totalsMapper(res.totals)
                  );
                }
                params.successCallback(res.rows, lastRow);
              })
              .catch((e) => {
                console.warn(e);
                self.gridOptions.api.setPinnedBottomRowData([]);
                params.successCallback([], request.startRow);
              });
          }
        }
      },
      gridOptions ?? {}
    );

    this.renderGridTableOptions = {
      pageInstance: this,
      gridParams: {
        tableReference,
        holder,
        agGridOptions: options
      },
      reportingParams,
      contextMenuOverride
    };
  }

  /**
   * @param {Record<string, any>} request
   * @returns {Promise<{ rows: any[]; lastRow: number; totals: any[] }>}
   */
  async fetchBoard(request) {
    try {
      const res = await axios.post(this.fullPath, {
        request,
        query: this.getQuery()
      });
      const data = res.data.data;
      if (!data.rows.length) {
        const error = new Error("No results found for your selected filters.");
        error.type = "info";
        error.titleText = "Info";
        throw error;
      }
      return data;
    } catch (error) {
      toast.displayToast({
        type: error.type ?? "error",
        titleText: error.titleText ?? "Error",
        bodyMessageText: error.response?.data?.errorMessage ?? error.message
      });
      throw error;
    }
  }

  render() {
    if (!this.gridOptions?.api) {
      renderGridTable(this.renderGridTableOptions);
    } else {
      this.refresh();
    }
  }

  refresh() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.purgeServerSideCache();
    } else {
      toast.displayToast({
        type: "error",
        titleText: "Error",
        bodyMessageText:
          "Something went wrong while refreshing the table. Please try again."
      });
    }
  }
}

export default AGGridTable;
